<template>
  <div class="m-3 flex justify-start">
    <div v-if="content" class="bg-white overflow-hidden shadow rounded-lg divide-y divide-gray-200">
      <div class="px-4 py-5 sm:px-6">
        {{ content.Description }}
      </div>
      <div class="px-4 py-5 sm:p-6">
        {{ content.Information }}
      </div>
      <div>
        <div>
          <a
            href="http://localhost:1337/admin/plugins/content-manager/collectionType/application::start-page.start-page/1"
            >edit</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StartPage",
  data() {
    return {
      content: null,
    };
  },
  mounted() {
    this.$http
      .get("/service/content")
      .then(response => {
        this.content = response.data;
      })
      .catch(error => {
        console.log(error);
      });
  },
};
</script>
